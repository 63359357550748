import { StartLogin } from './pre-login'
import React from 'react'
import spinner from '../assets/images/pokeball_gray.png'

export const PreLoginWrapper = () => {
  StartLogin()
  return <div><img className='spinner' src={spinner} alt={''}/></div>
}

export default PreLoginWrapper
