const POKEMON_OAUTH_PROVIDER_URI = process.env.REACT_APP_PTCS_OAUTH_DOMAIN
const CLIENT_ID = process.env.REACT_APP_PTCS_OAUTH_CLIENT_ID
const POST_LOGIN_URI = process.env.REACT_APP_SUPPORT_REDIRECT
const TOKEN_URI = `${POKEMON_OAUTH_PROVIDER_URI}/oauth2/token`
const POKEMON_ERROR_URL = process.env.REACT_APP_POKEMON_ERROR_URL
const SUPPORT_BASE_URL = process.env.REACT_APP_SUPPORT_BASE_URL
const JWT_TRANSLATION_URL = process.env.REACT_APP_JWT_TRANSLATION_URL
const ZENDESK_BASE_URL = process.env.REACT_APP_ZENDESK_BASE_URL
const NOT_FOUND_URL = process.env.REACT_APP_404_URL
export {
  POKEMON_OAUTH_PROVIDER_URI,
  CLIENT_ID,
  POST_LOGIN_URI,
  TOKEN_URI,
  POKEMON_ERROR_URL,
  SUPPORT_BASE_URL,
  JWT_TRANSLATION_URL,
  ZENDESK_BASE_URL,
  NOT_FOUND_URL
}
