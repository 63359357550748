import React, { useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Mascots } from '@pokemon/design.ui.containers.background'
import './app.css'
import PreLoginWrapper from './pages/pre-login-wrapper'
import PostLogin from './pages/post-login'
import RedirectToNotFound from './pages/root'

function App () {
  const formRef = useRef(null)
  const inputRef = useRef(null)

  return (
    <Router>
      <div className='routes'>
        <Routes>
          <Route path='/init' element={<PreLoginWrapper />} />
          <Route
            path='/finalize'
            element={<PostLogin formRef={formRef} inputRef={inputRef} />}
          />
          <Route path='*' element={<RedirectToNotFound />} />
        </Routes>
      </div>
      <Mascots />
    </Router>
  )
}

export default App
