import { CLIENT_ID, POKEMON_OAUTH_PROVIDER_URI, POST_LOGIN_URI, TOKEN_URI } from '../utils/config'

import cryptoBrowserify from 'crypto-browserify'

export function buildPokemonAuthUrl (state: string, challenge: string) {
  return `${POKEMON_OAUTH_PROVIDER_URI}/oauth2/auth?state=${state}&scope=openid+username+email+member_id+name+organized_play+screen_name&response_type=code&client_id=zendesk&code_challenge=${challenge}&code_challenge_method=S256&prompt=login&redirect_uri=${POST_LOGIN_URI}`
}

export function StartLogin () {
  const base64URLEncode = (str: { toString: (arg0: string) => string }) => {
    return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')
  }

  const sha256 = (buffer: any) => {
    return cryptoBrowserify.createHash('sha256').update(buffer).digest()
  }

  const verifier = base64URLEncode(cryptoBrowserify.randomBytes(32))
  const challenge = base64URLEncode(sha256(verifier))

  const state = base64URLEncode(cryptoBrowserify.randomBytes(16))

  window.sessionStorage.setItem('pkce_verifier', verifier)
  window.sessionStorage.setItem('pkce_token_uri', TOKEN_URI)
  window.sessionStorage.setItem('pkce_state', state)
  window.sessionStorage.setItem('pkce_client_id', String(CLIENT_ID))
  window.sessionStorage.setItem('pkce_redirect_uri', String(POST_LOGIN_URI))
  const pokemonAuthUrl = buildPokemonAuthUrl(state, challenge)
  console.log(pokemonAuthUrl)

  window.location.href = pokemonAuthUrl
}
