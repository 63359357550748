import React from 'react'
import spinner from '../assets/images/pokeball_gray.png'
import {
  POKEMON_ERROR_URL,
  SUPPORT_BASE_URL,
  JWT_TRANSLATION_URL,
  ZENDESK_BASE_URL
} from '../utils/config'
import axios, { AxiosRequestConfig } from 'axios'

const getRequestConfig = (
  accessToken: string,
  withCredentials = true
): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${accessToken}` },
    validateStatus: (code: number) => code === 201,
    withCredentials
  }
}

export const getToken = async () => {
  const queryString = new URLSearchParams(window.location.search)

  const code = queryString.get('code')
  const state = queryString.get('state')

  if (!state || state !== window.sessionStorage.getItem('pkce_state')) {
    window.location.assign(POKEMON_ERROR_URL!)
    return
  }

  const verifier = window.sessionStorage.getItem('pkce_verifier')
  const tokenUri = window.sessionStorage.getItem('pkce_token_uri')
  const redirectUri = window.sessionStorage.getItem('pkce_redirect_uri')
  const clientId = window.sessionStorage.getItem('pkce_client_id')

  if (!verifier || !tokenUri || !redirectUri || !clientId) {
    window.location.assign(POKEMON_ERROR_URL!)
    return
  }

  try {
    const body = `code=${code}&grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&redirect_uri=${redirectUri}`
    const response = await fetch(tokenUri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body
    })

    if (!response.ok) {
      window.location.assign(POKEMON_ERROR_URL!)
      return
    }
    const responseJson = await response.json()
    window.sessionStorage.setItem('oauth_access_token', responseJson.access_token)
  } catch (error) {
    window.location.assign(POKEMON_ERROR_URL!)
  }
}

// exported for testing purposes
export const translateJwt = () => {
  try {
    const sessionToken = window.sessionStorage.getItem('oauth_access_token')
    if (sessionToken) {
      return axios.get(JWT_TRANSLATION_URL!, getRequestConfig(sessionToken))
    }
  } catch (error) {}
  return undefined
}

interface PostLoginProps {
  inputRef: any
  formRef: any
}

export function PostLogin (props: PostLoginProps) {
  const { inputRef, formRef } = props

  const postProcess = async () => {
    try {
      await getToken()
      if (window.location.href === POKEMON_ERROR_URL) {
        return
      }
      const response = await translateJwt()
      if (response === undefined || response.status > 299) {
        window.location.assign(POKEMON_ERROR_URL!)
        return
      }
      inputRef.current.value = response.data
      formRef.current.submit()
    } catch (error) {
      window.location.assign(POKEMON_ERROR_URL!)
    }
  }

  return (
    <div>
      <img className="spinner" src={spinner} alt={''} onLoad={postProcess} />
      <form
        ref={formRef}
        action={`${ZENDESK_BASE_URL}/access/jwt?return_to=${SUPPORT_BASE_URL}`}
        method="post"
      >
        <input ref={inputRef} type="hidden" name="jwt"></input>
      </form>
    </div>
  )
}

export default PostLogin
