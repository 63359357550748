import React, { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './app'
import spinner from './assets/images/pokeball_gray.png'
import './utils/i18n'
import reportWebVitals from './utils/reportWebVitals'

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<img className='spinner' src={spinner} alt='…' />}>
      <App />
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
